.pageContainerC {
    background-color: #cccccc;
    color: rgb(77, 77, 77); 
    min-height: 100vh; 
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    }
  
  .rectangleC {
    background-color: #fff;
    border-radius: 26px;
    height: 5%;
    width: 40%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    padding-bottom: 5%;
  }
  
  .topboxC {
    margin: -5% 0 0;
    width: 500px;
    min-height: 160px;
    display: 100px;
    flex-direction: row;
    flex-grow: 1;
    align-self: center;
    text-align: center;
  }
  
.h1C {
    font-size: 40px;
    color: #B9030A;
}
.imgcancelC {
    width: 200px;
    height: 200px;
}

.midboxC {
    display: flex;
    margin: 0 0 20px;
    justify-content: center;
}

.qBoxC {
    margin: 10% 0 0;
    height: 13%;
    text-align: center;
}
.imglogoC {
    margin: auto;
    height: auto;
    width: 200px;
    border: 2px solid #51ADE5; 
    border-radius: 5px;
}
