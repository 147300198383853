.pageContainer {
    background-color: #cccccc;
    color: rgb(77, 77, 77); 
    min-height: 100vh; 
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    }
  
  .rectangle {
    background-color: #fff;
    border-radius: 26px;
    height: '20%';
    width: 40%;
    position: relative;
    padding: 40px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
  }
  
  .topBox {
    width: max;
    min-height: 160px;
    display: flex;
    flex-direction: row;
    flex-grow: 4;
  }
  
  .rectanglebar {
    background-color: rgb(77, 77, 77); 
    width: 10px;
    height: 140%;
  }
  
  .h1TextBox {
    height: 62%;
    align-items: center; 
    display: flex;
    justify-content: center;
    padding: 5px;
  }
  
  .h1text {
    font-size: 4em;
    font-style: bold;
    margin: 15px;justify-content: center;
  }
  
  .midBox {
    height: 100%;
    width: 100%;
    flex-grow: 2;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  
  .h3text {
    font-size: 15px;
    font-style: bold;
  }
  
  .qBox {
    height: 50%;
    text-align: center;
    margin: 5px;
  }
  
  .imglogo {
    margin: auto;
    height: auto;
    width: 200px;
    border: 2px solid #51ADE5; 
    border-radius: 5px;
  }
  
  .upperMidBox {
    height: 50%;
    text-align: center;
    margin: 5px;
  }
  
  .subtitletext {
    font-size: 12px;
  }